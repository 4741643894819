<template>

  <div v-if="nuevo" style="width: 100%; height: 45px;  ">
    <a>
      <img style="padding:2px" src="@/assets/mensajeT.svg" class="image-icon mt-1 mr-0" />
    </a>
  </div>
  <div v-else style="width: 100%; height: 45px;  ">

    <a style=" padding:2px;  "     @click.prevent="activar">
      <img src="@/assets/mensajeW.svg" class="image-icon mt-1 mr-0" />
    </a>

  </div>
</template>
<script>
import ChatContainer from './ChatContainer'
import * as firestoreService from '@/database/firestore'
import {
	doc,
	onSnapshot
} from 'firebase/firestore'
import { firestoreDb } from '@/database'

export default {
  components: {
		ChatContainer,
	},

data() {
  return {
    mensaje: false,
    value: false,
    theme: 'light',
			showChat: false,
      roomId: '',
			currentUserId: '140',
			isDevice: false,
			showDemoOptions: true,
      nuevo: false

  }
},
watch: {
		currentUserId() {
			this.showChat = false
			setTimeout(() => (this.showChat = true), 150)
		}
	},
async mounted() {
    this.userId = this.$route.params.user
    this.roomId = this.$route.params.event

    console.log("estamos currentid", this.userId, this.roomId)

      console.log('hashchange', this.$route.params)

     // const room = await firestoreService.getRoomEvent(this.roomId)
      const promise = await firestoreService.getUserByTeam(this.userId)
      if (promise.data.length > 0) {
        this.currentView = promise.data[0].id
      } else {
        this.nuevo = true
      }

  //    console.log('hashchange 2', promise, room)
   //   this.currentView = promise.data[0].id
    //  this.roomId = room.data[0].id
   // this.roomId = '0'
  //    const listen = await this.listenLastMessage(this.roomId)
   //   console.log('room', listen)
      this.showChat = true
     /*  const unsub = onSnapshot(doc(firestoreDb, "chatRooms", this.roomId), (doc) => {
        // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(  " data: ", doc.data());
      }) */
},

methods: {
  activar() {
    this.mensaje = false
    const routeData = this.$router.resolve({
      name: 'rooms',
      query: {
        user: this.$route.params.user,
        event: this.$route.params.event
      }
    })
    window.open(routeData.href, '_blank')
  },
  active() {
        this.value = true;
        window.scrollTo(0, 70);
      },
  desactive() {
    this.value = false
  },

listenLastMessage(room) {
  const listener = firestoreService.listenLastMessage(
    room,
    callback => {
      console.log('callback', callback[0].seen )
      if (callback[0].seen === undefined) {
        console.log("true")
        this.mensaje = true
      } else {
        console.log("false")
        this.mensaje = false
      }
    }
  )
  return listener
}
}
}
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 999;
  top: 20%;
  left: 50%;
  width: 300px;
  margin-left: -150px;
}
.image-icon {
  width: 40px;
}

@media only screen and (max-width: 600px) {

  .image-icon {
      width: 40px;
  }
}
body {
	background: #fafafa;
	margin: 0;

}

input {
	-webkit-appearance: none;
}

.app-container {
	font-family: 'Quicksand', sans-serif;
	padding: 20px 30px 30px;
}

.app-mobile {
	padding: 0;

	&.app-mobile-dark {
		background: #131415;
	}

	.user-logged {
		margin: 10px 5px 0 10px;
	}

	select {
		margin: 10px 0;
	}

	.button-theme {
		margin: 10px 10px 0 0;

		.button-github {
			height: 23px;

			img {
				height: 23px;
			}
		}
	}
}

.user-logged {
	font-size: 12px;
	margin-right: 5px;
	margin-top: 10px;

	&.user-logged-dark {
		color: #fff;
	}
}

select {
	height: 20px;
	outline: none;
	border: 1px solid #e0e2e4;
	border-radius: 4px;
	background: #fff;
	margin-bottom: 20px;
}

.button-theme {
	float: right;
	display: flex;
	align-items: center;

	.button-light {
		background: #fff;
		border: 1px solid #46484e;
		color: #46484e;
	}

	.button-dark {
		background: #1c1d21;
		border: 1px solid #1c1d21;
	}

	button {
		color: #fff;
		outline: none;
		cursor: pointer;
		border-radius: 4px;
		padding: 6px 12px;
		margin-left: 10px;
		border: none;
		font-size: 14px;
		transition: 0.3s;
		vertical-align: middle;

		&.button-github {
			height: 30px;
			background: none;
			padding: 0;
			margin-left: 20px;

			img {
				height: 30px;
			}
		}

		&:hover {
			opacity: 0.8;
		}

		&:active {
			opacity: 0.6;
		}

		@media only screen and (max-width: 768px) {
			padding: 3px 6px;
			font-size: 13px;
		}
	}
}

.version-container {
	padding-top: 20px;
	text-align: right;
	font-size: 14px;
	color: grey;
}
</style>
