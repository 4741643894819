import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const environment = process.env.VUE_APP_ENVIRONMENT
let config = {}
console.log("environment: ", environment)
if (environment === "TEST") {
  config = {
    apiKey: 'AIzaSyDzXJQZ4_et8xxYPSqf38_eL6OE6rDITdM',
    authDomain: 'chatsye90.firebaseapp.com',
    databaseURL: 'https://chatsye90-default-rtdb.firebaseio.com',
    projectId: 'chatsye90',
    storageBucket: 'chatsye90.appspot.com',
    messagingSenderId: '1070187366042',
    appId: '1:1070187366042:web:ecf6a10a8d574a0dc24123'
  }
} else {
  config = {
    apiKey: "AIzaSyC7dB4c60yWXhpqW9er1sCNvCRVPwba4Fo",
    authDomain: "setupyourevents.firebaseapp.com",
    databaseURL: "https://setupyourevents-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "setupyourevents",
    storageBucket: "setupyourevents.appspot.com",
    messagingSenderId: "499057711926",
    appId: "1:499057711926:web:f11b889c81d77467cd3197",
    measurementId: "G-8GD7BYB4BT"
  }
}

console.log("config", config.projectId)

const app = initializeApp(config)

/* const auth = getAuth(app);

createUserWithEmailAndPassword(auth, 'soporte@bridalsetup.com', 'Bridal*2023')
  .then((userCredential) => {
    // Signed in
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });
 */
export const firestoreDb = getFirestore()
export const realtimeDb = getDatabase()
export const storage = getStorage()
