import { createApp, h } from 'vue'
import chat from './chat.vue'
import chatButton from './chatButton.vue'
import rooms from './rooms.vue'
import roomsButton from './roomsButton.vue'
import App from './App.vue'
import { createWebHashHistory, createRouter } from 'vue-router'

const routes = [
  { path: '/chat/:user/:event',
    name: 'chat',
    component: chat,
    params: true },
  { path: '/rooms/:user/:event',
    name: 'rooms',
    component: rooms,
    params: true },
  {
    path: '/button/:color/:user/:event/:cliente',
    component: chatButton,
    params: true
  },
  {
    path: '/room/:color/:user/:event',
    component: roomsButton,
    params: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const app = createApp(App)
app.use(router)
app.mount('#app')
