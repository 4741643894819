<template>
  <div v-if="load">
  <div v-if="nuevo || nuevoRoom" style="width: 100%; height: 45px;  ">
    <a v-if="color === 'black'" @click.prevent="create()">
      <img style="padding:2px" src="@/assets/mensajeR.svg" class="image-icon mt-1 mr-0" />
    </a>
    <a v-else>
      <img style="padding:2px" src="@/assets/mensajeT.svg" class="image-icon mt-1 mr-0" />
    </a>
  </div>

  <div v-else style="width: 100%; height: 45px;">
    <a v-if="mensaje" @click.prevent="activar">
      <img style="  padding:2px " src="@/assets/mensajeNew.svg" class="image-icon mt-1 mr-0" />
    </a>
    <a v-if="mensaje === false && color === 'front'" style=" padding:2px;  "  @click.prevent="activar">
      <img src="@/assets/mensajeW.svg" class="image-icon mt-1 mr-0" />
    </a>
    <a v-if="mensaje === false && color === 'black'" style=" padding:2px;  "  @click.prevent="activar">
      <img src="@/assets/mensaje.svg" class="image-icon mt-1 mr-0" />
    </a>
  </div>
</div>
</template>
<script>
import ChatContainer from './ChatContainer'
import * as firestoreService from '@/database/firestore'
import {
	doc,
	onSnapshot
} from 'firebase/firestore'
import { firestoreDb } from '@/database'
import * as userService from '@/composables/useUsers.js'

export default {
  components: {
		ChatContainer,
	},

data() {
  return {
    mensaje: false,
    value: false,
    theme: 'light',
			showChat: false,
      roomId: '',
			clientId: '',
			isDevice: false,
			showDemoOptions: true,
      color: '',
      nuevo: false,
      nuevoRoom: false,
      addRoomUsername: '',
      userId: '',
      load: false

  }
},
watch: {
    clientId() {
			this.showChat = false
			setTimeout(() => (this.showChat = true), 150)
		}
	},
async mounted() {
    this.userId = this.$route.params.user
    this.roomId = this.$route.params.event
    this.color = this.$route.params.color
    this.clientId = this.$route.params.cliente



    console.log("estamos currentid", this.userId, this.roomId)

      console.log('hashchange', this.$route.params)

      const promise = await firestoreService.getUserByTeam(this.userId)
      console.log("promise", promise)
      if (promise.data.length > 0) {
        this.currentView = promise.data[0].id
      } else {
        this.nuevo = true
      }

      const room = await firestoreService.getRoomEvent(this.roomId)
      console.log("oom", room)
      if (room.data.length > 0) {
        this.roomId = room.data[0].id
      } else {
        this.nuevoRoom = true
      }
      console.log("false", this.nuevo, this.nuevoRoom)


      if (this.nuevo === false && this.nuevoRoom === false) {
        const listen = await this.listenLastMessage(this.roomId)
        console.log('room', listen)
      }

      this.showChat = true
      this.load = true
     /*  const unsub = onSnapshot(doc(firestoreDb, "chatRooms", this.roomId), (doc) => {
        // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(  " data: ", doc.data());
      }) */
},

methods: {
  activar() {
    this.mensaje = false
    const routeData = this.$router.resolve({
      name: 'chat',
      query: {
        user: this.$route.params.user,
        event: this.$route.params.event
      }
    })
    window.open(routeData.href, '_blank')
  },

  // funcion de crear usuario si no existe, se pasa el id del usuario comercial en internal
  // debemos de buscar el username en graphql
  // tambien creamos el room con el id del evento si existe o no, y el user del usuario

  async createUser(idToCreate, rol) {
      // REVISAMOS SI EXISTIA EL USUARIO

        const username = await userService.getUserData(idToCreate)
        console.log("username", username)
        const { id } = await firestoreService.addUser({
          internal: idToCreate,
          username: username[0].name,
          rol: rol
        })
        await firestoreService.updateUser(id, { _id: id })
        return id

    },

	async createRoom() {
			this.disableForm = true
      let id = 0
      const promise = await firestoreService.getUserByTeam(this.clientId)

      console.log('users', promise.data.length)
      if (promise.data.length > 0) {
        console.log('existe', promise.data[0].id)
        id = promise.data[0].id
      } else {
        id = await this.createUser(this.clientId, 'cliente')
      }

        console.log('promise', id, this.currentView)
			  await firestoreService.addRoom({
          users: [id, this.currentView],
          event: this.roomId,
          lastUpdated: new Date()
        })

			this.addNewRoom = false
			this.addRoomUsername = ''
			//this.fetchRooms()
	},

  async create() {
    if (this.nuevo === true) {
        var id = await this.createUser(this.userId, 'comercial')

          this.currentView = id
          this.nuevo = false
      }
    if (this.nuevoRoom === true) {
         this.createRoom()
          this.nuevoRoom = false
    }
},

  active() {
        this.value = true;
        window.scrollTo(0, 70);
      },

  desactive() {
    this.value = false
  },

listenLastMessage(room) {
  const listener = firestoreService.listenLastMessage(
    room,
    callback => {
      console.log('callback', callback)
      if (callback.length > 0) {
        if (callback[0].seen === undefined) {
          console.log("true", callback[0].sender_id, this.currentView)
          if (this.currentView !== callback[0].sender_id) {
            console.log("true")
            this.mensaje = true
          } else {
            console.log("false")
            this.mensaje = false
          }
        } else {
          console.log("false")
          this.mensaje = false
        }
      }
    }
  )
  return listener
}
}
}
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 999;
  top: 20%;
  left: 50%;
  width: 300px;
  margin-left: -150px;
}
.image-icon {
  width: 35px;
}

@media only screen and (max-width: 600px) {

  .image-icon {
      width: 35px;
  }
}
body {
	background-color: green;
	margin: 0;

}

input {
	-webkit-appearance: none;
}

.app-container {
	font-family: 'Quicksand', sans-serif;
	padding: 20px 30px 30px;
}

.app-mobile {
	padding: 0;

	&.app-mobile-dark {
		background: #ffffff;
	}

	.user-logged {
		margin: 10px 5px 0 10px;
	}

	select {
		margin: 10px 0;
	}

	.button-theme {
		margin: 10px 10px 0 0;

		.button-github {
			height: 23px;

			img {
				height: 23px;
			}
		}
	}
}

.user-logged {
	font-size: 12px;
	margin-right: 5px;
	margin-top: 10px;

	&.user-logged-dark {
		color: #fff;
	}
}

select {
	height: 20px;
	outline: none;
	border: 1px solid #e0e2e4;
	border-radius: 4px;
	background: #fff;
	margin-bottom: 20px;
}

.button-theme {
	float: right;
	display: flex;
	align-items: center;

	.button-light {
		background: #fff;
		border: 1px solid #46484e;
		color: #46484e;
	}

	.button-dark {
		background: #1c1d21;
		border: 1px solid #1c1d21;
	}

	button {
		color: #fff;
		outline: none;
		cursor: pointer;
		border-radius: 4px;
		padding: 6px 12px;
		margin-left: 10px;
		border: none;
		font-size: 14px;
		transition: 0.3s;
		vertical-align: middle;

		&.button-github {
			height: 30px;
			background: none;
			padding: 0;
			margin-left: 20px;

			img {
				height: 30px;
			}
		}

		&:hover {
			opacity: 0.8;
		}

		&:active {
			opacity: 0.6;
		}

		@media only screen and (max-width: 768px) {
			padding: 3px 6px;
			font-size: 13px;
		}
	}
}

.version-container {
	padding-top: 20px;
	text-align: right;
	font-size: 14px;
	color: grey;
}
</style>
