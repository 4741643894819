<template>
  <router-view />
</template>
<style>

body {
	background-color: rgba(255, 255, 255, 0);
	margin: 0;

}
</style>
