
export const getUserData = async (id) => {
  const environment = process.env.VUE_APP_ENVIRONMENT

  let server = process.env.VUE_APP_GQLSERVER_PROD
  console.log('environment', environment)

  console.log('server', server)

  if (environment === 'TEST') {
     server = process.env.VUE_APP_GQLSERVER_DEV
  }

  console.log('server', server)
         var operation = `query MyQuery {
          users(where: {id: {_eq: "${id}"}}) {
            email
            id
            name
            team_id
          }
        }`

        try {
          let res = await fetch(server, {
           method: 'POST',
           headers: {
            'Content-type': 'application/json',
            'x-hasura-admin-secret': 'bridSetGql*has' // Replace it with your key
           },
           body: JSON.stringify(
            {
              query: operation,
              operationName: 'MyQuery'
            })
          })
          res = await res.json()

          console.log('response', res)
          const response = res.data.users
          console.log('response', response)
          return response
         } catch (error) {
          console.log(error)
         }
    }
